exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-500-tsx": () => import("./../../../src/pages/500.tsx" /* webpackChunkName: "component---src-pages-500-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-auctions-tsx": () => import("./../../../src/pages/auctions.tsx" /* webpackChunkName: "component---src-pages-auctions-tsx" */),
  "component---src-pages-autoinvest-terms-tsx": () => import("./../../../src/pages/autoinvest-terms.tsx" /* webpackChunkName: "component---src-pages-autoinvest-terms-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-crowdfunding-tsx": () => import("./../../../src/pages/crowdfunding.tsx" /* webpackChunkName: "component---src-pages-crowdfunding-tsx" */),
  "component---src-pages-crowdlending-tsx": () => import("./../../../src/pages/crowdlending.tsx" /* webpackChunkName: "component---src-pages-crowdlending-tsx" */),
  "component---src-pages-finance-business-loan-tsx": () => import("./../../../src/pages/finance/business-loan.tsx" /* webpackChunkName: "component---src-pages-finance-business-loan-tsx" */),
  "component---src-pages-finance-commercial-mortgage-tsx": () => import("./../../../src/pages/finance/commercial-mortgage.tsx" /* webpackChunkName: "component---src-pages-finance-commercial-mortgage-tsx" */),
  "component---src-pages-finance-company-acquisition-tsx": () => import("./../../../src/pages/finance/company-acquisition.tsx" /* webpackChunkName: "component---src-pages-finance-company-acquisition-tsx" */),
  "component---src-pages-finance-company-leasing-agropool-tsx": () => import("./../../../src/pages/finance/company-leasing/agropool.tsx" /* webpackChunkName: "component---src-pages-finance-company-leasing-agropool-tsx" */),
  "component---src-pages-finance-company-leasing-baumaschinenpool-tsx": () => import("./../../../src/pages/finance/company-leasing/baumaschinenpool.tsx" /* webpackChunkName: "component---src-pages-finance-company-leasing-baumaschinenpool-tsx" */),
  "component---src-pages-finance-company-leasing-tsx": () => import("./../../../src/pages/finance/company-leasing.tsx" /* webpackChunkName: "component---src-pages-finance-company-leasing-tsx" */),
  "component---src-pages-finance-credit-rating-tsx": () => import("./../../../src/pages/finance/credit-rating.tsx" /* webpackChunkName: "component---src-pages-finance-credit-rating-tsx" */),
  "component---src-pages-finance-faq-tsx": () => import("./../../../src/pages/finance/faq.tsx" /* webpackChunkName: "component---src-pages-finance-faq-tsx" */),
  "component---src-pages-finance-sme-loan-tsx": () => import("./../../../src/pages/finance/sme-loan.tsx" /* webpackChunkName: "component---src-pages-finance-sme-loan-tsx" */),
  "component---src-pages-give-money-tsx": () => import("./../../../src/pages/give-money.tsx" /* webpackChunkName: "component---src-pages-give-money-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-invest-autoinvest-tsx": () => import("./../../../src/pages/invest/autoinvest.tsx" /* webpackChunkName: "component---src-pages-invest-autoinvest-tsx" */),
  "component---src-pages-invest-business-loans-tsx": () => import("./../../../src/pages/invest/business-loans.tsx" /* webpackChunkName: "component---src-pages-invest-business-loans-tsx" */),
  "component---src-pages-invest-faq-tsx": () => import("./../../../src/pages/invest/faq.tsx" /* webpackChunkName: "component---src-pages-invest-faq-tsx" */),
  "component---src-pages-invest-institutional-investors-tsx": () => import("./../../../src/pages/invest/institutional-investors.tsx" /* webpackChunkName: "component---src-pages-invest-institutional-investors-tsx" */),
  "component---src-pages-invest-risk-return-tsx": () => import("./../../../src/pages/invest/risk-return.tsx" /* webpackChunkName: "component---src-pages-invest-risk-return-tsx" */),
  "component---src-pages-invest-sdg-tsx": () => import("./../../../src/pages/invest/sdg.tsx" /* webpackChunkName: "component---src-pages-invest-sdg-tsx" */),
  "component---src-pages-invest-statistics-tsx": () => import("./../../../src/pages/invest/statistics.tsx" /* webpackChunkName: "component---src-pages-invest-statistics-tsx" */),
  "component---src-pages-investment-offers-tsx": () => import("./../../../src/pages/investment-offers.tsx" /* webpackChunkName: "component---src-pages-investment-offers-tsx" */),
  "component---src-pages-need-money-tsx": () => import("./../../../src/pages/need-money.tsx" /* webpackChunkName: "component---src-pages-need-money-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-success-stories-index-tsx": () => import("./../../../src/pages/success-stories/index.tsx" /* webpackChunkName: "component---src-pages-success-stories-index-tsx" */),
  "component---src-pages-success-stories-investment-credit-tsx": () => import("./../../../src/pages/success-stories/investment-credit.tsx" /* webpackChunkName: "component---src-pages-success-stories-investment-credit-tsx" */),
  "component---src-pages-success-stories-succession-financing-tsx": () => import("./../../../src/pages/success-stories/succession-financing.tsx" /* webpackChunkName: "component---src-pages-success-stories-succession-financing-tsx" */),
  "component---src-pages-success-stories-working-capital-tsx": () => import("./../../../src/pages/success-stories/working-capital.tsx" /* webpackChunkName: "component---src-pages-success-stories-working-capital-tsx" */),
  "component---src-pages-team-advisors-andre-mueller-tsx": () => import("./../../../src/pages/team/advisors/andre-mueller.tsx" /* webpackChunkName: "component---src-pages-team-advisors-andre-mueller-tsx" */),
  "component---src-pages-team-advisors-boris-simonovic-tsx": () => import("./../../../src/pages/team/advisors/boris-simonovic.tsx" /* webpackChunkName: "component---src-pages-team-advisors-boris-simonovic-tsx" */),
  "component---src-pages-team-advisors-christian-ulrich-tsx": () => import("./../../../src/pages/team/advisors/christian-ulrich.tsx" /* webpackChunkName: "component---src-pages-team-advisors-christian-ulrich-tsx" */),
  "component---src-pages-team-advisors-cornelia-gut-villa-tsx": () => import("./../../../src/pages/team/advisors/cornelia-gut-villa.tsx" /* webpackChunkName: "component---src-pages-team-advisors-cornelia-gut-villa-tsx" */),
  "component---src-pages-team-advisors-jean-pierre-huwyler-tsx": () => import("./../../../src/pages/team/advisors/jean-pierre-huwyler.tsx" /* webpackChunkName: "component---src-pages-team-advisors-jean-pierre-huwyler-tsx" */),
  "component---src-pages-team-advisors-roger-wiesendanger-tsx": () => import("./../../../src/pages/team/advisors/roger-wiesendanger.tsx" /* webpackChunkName: "component---src-pages-team-advisors-roger-wiesendanger-tsx" */),
  "component---src-pages-team-advisors-rudolf-duerst-tsx": () => import("./../../../src/pages/team/advisors/rudolf-duerst.tsx" /* webpackChunkName: "component---src-pages-team-advisors-rudolf-duerst-tsx" */),
  "component---src-pages-team-advisors-thomas-amstutz-tsx": () => import("./../../../src/pages/team/advisors/thomas-amstutz.tsx" /* webpackChunkName: "component---src-pages-team-advisors-thomas-amstutz-tsx" */),
  "component---src-pages-team-board-christoph-ammann-tsx": () => import("./../../../src/pages/team/board/christoph-ammann.tsx" /* webpackChunkName: "component---src-pages-team-board-christoph-ammann-tsx" */),
  "component---src-pages-team-board-eric-lohrer-tsx": () => import("./../../../src/pages/team/board/eric-lohrer.tsx" /* webpackChunkName: "component---src-pages-team-board-eric-lohrer-tsx" */),
  "component---src-pages-team-board-hannes-caj-tsx": () => import("./../../../src/pages/team/board/hannes-caj.tsx" /* webpackChunkName: "component---src-pages-team-board-hannes-caj-tsx" */),
  "component---src-pages-team-board-juerg-hunziker-tsx": () => import("./../../../src/pages/team/board/juerg-hunziker.tsx" /* webpackChunkName: "component---src-pages-team-board-juerg-hunziker-tsx" */),
  "component---src-pages-team-board-karin-rhomberg-tsx": () => import("./../../../src/pages/team/board/karin-rhomberg.tsx" /* webpackChunkName: "component---src-pages-team-board-karin-rhomberg-tsx" */),
  "component---src-pages-team-board-laurent-decrue-tsx": () => import("./../../../src/pages/team/board/laurent-decrue.tsx" /* webpackChunkName: "component---src-pages-team-board-laurent-decrue-tsx" */),
  "component---src-pages-team-board-urs-hofer-tsx": () => import("./../../../src/pages/team/board/urs-hofer.tsx" /* webpackChunkName: "component---src-pages-team-board-urs-hofer-tsx" */),
  "component---src-pages-team-employees-alwin-meyer-tsx": () => import("./../../../src/pages/team/employees/alwin-meyer.tsx" /* webpackChunkName: "component---src-pages-team-employees-alwin-meyer-tsx" */),
  "component---src-pages-team-employees-andreas-hug-tsx": () => import("./../../../src/pages/team/employees/andreas-hug.tsx" /* webpackChunkName: "component---src-pages-team-employees-andreas-hug-tsx" */),
  "component---src-pages-team-employees-fabian-lehner-tsx": () => import("./../../../src/pages/team/employees/fabian-lehner.tsx" /* webpackChunkName: "component---src-pages-team-employees-fabian-lehner-tsx" */),
  "component---src-pages-team-employees-felix-attinger-tsx": () => import("./../../../src/pages/team/employees/felix-attinger.tsx" /* webpackChunkName: "component---src-pages-team-employees-felix-attinger-tsx" */),
  "component---src-pages-team-employees-giuseppe-caruso-tsx": () => import("./../../../src/pages/team/employees/giuseppe-caruso.tsx" /* webpackChunkName: "component---src-pages-team-employees-giuseppe-caruso-tsx" */),
  "component---src-pages-team-employees-matthias-huegle-tsx": () => import("./../../../src/pages/team/employees/matthias-huegle.tsx" /* webpackChunkName: "component---src-pages-team-employees-matthias-huegle-tsx" */),
  "component---src-pages-team-employees-stefan-naegeli-tsx": () => import("./../../../src/pages/team/employees/stefan-naegeli.tsx" /* webpackChunkName: "component---src-pages-team-employees-stefan-naegeli-tsx" */),
  "component---src-pages-team-employees-urs-schaerrer-tsx": () => import("./../../../src/pages/team/employees/urs-schaerrer.tsx" /* webpackChunkName: "component---src-pages-team-employees-urs-schaerrer-tsx" */),
  "component---src-pages-terms-2020-07-01-tsx": () => import("./../../../src/pages/terms-2020-07-01.tsx" /* webpackChunkName: "component---src-pages-terms-2020-07-01-tsx" */),
  "component---src-pages-terms-2022-12-01-tsx": () => import("./../../../src/pages/terms-2022-12-01.tsx" /* webpackChunkName: "component---src-pages-terms-2022-12-01-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */)
}

