/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
require('@fontsource/fira-sans/300.css');
require('@fontsource/fira-sans/400.css');
require('@fontsource/fira-sans/500.css');
require('@fontsource/fira-sans/600.css');
require('@fontsource/fira-sans/700.css');
require('./static/base.css');

const ReactDOM = require('react-dom/client');
const { ApplicationInsights } = require('@microsoft/applicationinsights-web');

exports.onClientEntry = () => {
    return new Promise((resolve, reject) => {
        window.__polyfillio__ = () => {
            resolve();
        };

        const features = [];

        if (!('fetch' in window)) {
            features.push('fetch');
        }

        // In IE11 window.URL is not a constructor, it's a plain object
        if (!('URL' in window) || typeof window.URL !== 'function') {
            features.push('URL');
        }

        // ... detect other missing features

        if (features.length) {
            const s = document.createElement('script');
            s.src = `https://cdn.polyfill.io/v3/polyfill.min.js?features=${features.join(
                '%2C'
            )}&rum=1&flags=gated&callback=__polyfillio__`;
            s.async = true;
            s.onerror = reject;
            document.head.appendChild(s);
        } else {
            resolve();
        }

        // register app insights
        if (window.GATSBY_INSTRUMENTATION_KEY) {
            const appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: window.GATSBY_INSTRUMENTATION_KEY,
                    enableAutoRouteTracking: true,
                },
            });
            appInsights.loadAppInsights();
            appInsights.trackPageView();
            window.appInsights = appInsights;
        }

        setConsentData();
    });
};

// Logs when the client route changes
exports.onRouteUpdate = () => {
    if (window.appInsights) {
        window.appInsights.trackPageView();
    }
};

exports.replaceHydrateFunction = () => {
    return (element, container) => {
        const root = ReactDOM.createRoot(container);
        root.render(element);
    };
};

function setConsentData() {
    // Step 2: This snippet sets a default consent state, instructing Google's technologies how to behave if no consent is present.
    window.dataLayer = window.dataLayer || [];
    function gtag() {
        dataLayer.push(arguments);
    }

    gtag('consent', 'default', {
        analytics_storage: 'denied',
        ad_storage: 'denied',
        ad_user_data: 'denied',
        ad_personalization: 'denied',
        functionality_storage: 'denied',
        region: [
            'AT',
            'BE',
            'BG',
            'HR',
            'CY',
            'CZ',
            'DK',
            'EE',
            'FI',
            'FR',
            'DE',
            'GR',
            'HU',
            'IS',
            'IE',
            'IT',
            'LV',
            'LI',
            'LT',
            'LU',
            'MT',
            'NL',
            'NO',
            'PL',
            'PT',
            'RO',
            'SK',
            'SI',
            'ES',
            'SE',
            'UK',
            'CH',
        ],
    });

    // Step 3: This snippet sends consent updates from the HubSpot cookie banner to Google's tags using Consent Mode v2
    var _hsp = (window._hsp = window._hsp || []);
    _hsp.push([
        'addPrivacyConsentListener',
        function(consent) {
            var hasAnalyticsConsent =
                consent && (consent.allowed || (consent.categories && consent.categories.analytics));
            var hasAdsConsent =
                consent && (consent.allowed || (consent.categories && consent.categories.advertisement));
            var hasFunctionalityConsent =
                consent && (consent.allowed || (consent.categories && consent.categories.functionality));

            gtag('consent', 'update', {
                ad_storage: hasAdsConsent ? 'granted' : 'denied',
                analytics_storage: hasAnalyticsConsent ? 'granted' : 'denied',
                ad_user_data: hasAdsConsent ? 'granted' : 'denied',
                ad_personalization: hasAdsConsent ? 'granted' : 'denied',
                functionality_storage: hasFunctionalityConsent ? 'granted' : 'denied',
            });
        },
    ]);
}
